.containerUnity {
  position: fixed;
  /* The container determains the size. */
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  top: 50%;
  left: 40%;
  z-index: 10;
}

.unity-container{
  width: 100vw;
  height: 100vh;
  display: block;
}



.unity{
  z-index: -10;
  position: absolute;

}

.stars-loading{
position: fixed;
z-index: 20;
}
@media screen and (max-width: 560px) {
.containerUnity {
  position: fixed;
  /* The container determains the size. */
 text-align: center;
}
}

.hetero-unity-footer{
  width: 45%;
  height: 4%;
  background-color: transparent;
  align-items: center;
  text-align: center;
  position: fixed;
  top:72%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  margin: auto;
}

.unity-loading-text-mobile-container{
text-align: center;
}

.unity-loading-text-mobile{
  font-size: 24px;
  color: rgb(166, 166, 166);
  font-family: 'Montserrat';
  z-index: 0;
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100vw;
  align-items: center;
  align-self: center;
  transition: opacity 2.5s, visibility 2.5s;
}

.loading-unity-gif-mobile{
  position: fixed;
  width: 100%;
  height: 270px;
  top:20%
  /* top: 10%; */
}
.unity-loading{

  font-size: 24px;
  color: rgb(166, 166, 166);
  background-color: transparent;
  font-family: 'Montserrat-Bold', sans-serif;;
  z-index: 0;
  position: fixed;
  padding-bottom: 200px;
}
.containerUnity > .loading-overlay {
  /* We'll render the overlay on top of the Unity Application. */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  /* We'll set the following Flex properties in order to center the text. */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.containerUnity > .unity {
  /* The Unity Application matches it size to the container. */
  width: 100%;
  height: 100%;
}

#loading{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgb(0, 0, 0);
  color: white;
  font-size: 32px;
  opacity: 1;
  transition: 0.5s;
  visibility: visible;
  align-items: center;
  text-align: center;
  z-index: -2;
}
#loadingGif{
  height: 70%;
  padding-top: 5%;
}

#loading.hidden{
  opacity: 0;
  visibility: hidden;
}


.loadingEarth{

  align-items: center;;

}
.loadingGifEarth{
  position:fixed;
  /* padding-left: 5%; */
  top:20%;
  left:35%;
  /* right:0;
  bottom:0; */
  background-color: transparent;
  color: white;
  font-size: 32px;
  opacity: 1;
  transition: 0.5s;
  visibility: visible;
  align-items: center;
  text-align: center;
  z-index: -1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
}

.loading-image{
  position: fixed;
  z-index: -1;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.parent {
  height: 100vh;
  background: transparent;
  z-index: -10;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .child {
  background-color: transparent;
  width:  600px;
  height: 500px;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  }

  .child-text{
    padding-bottom: 20px;
  } 

  .btn-group{
    position: fixed !important;
    z-index: 13 !important;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    bottom: 100px;
    left:40%;
  }

  .btn-twitter{
    width: 3vw;
    height: 3vw;
    background: url("/public/twitter-01.png");
    background-position: center;
    background-size: 100% auto;
    border: 2rem;
    margin: 30px; 
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    cursor: pointer;
  }


  .btn-discord{
    width: 3vw;
    height: 3vw;
    background: url("/public/discord-01.png");
    background-position: center;
    background-size: 100% auto;
    border: 2rem;
    margin: 30px;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    cursor: pointer;
  }


  .btn-instagram{
    width: 3vw;
    height: 3vw;
    background: url("/public/instagram-01.png");
    background-position: center;
    background-size: 100% auto;
    border: 2rem;
    margin: 30px;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    cursor: pointer;
  }


  .btn-youtube{
    width: 3vw;
    height: 3vw;
    background: url("/public/youtube-01.png");
    background-position: center;
    background-size: 100% auto;
    border: 2rem;
    margin: 30px;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    cursor: pointer;
  }